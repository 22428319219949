import React from "react";
import { Router, Location, Redirect } from "@reach/router";
import { LanguageContextProvider } from "./contexts/language/index";
import { SnackbarContextProvider } from "./contexts/snackbar/SnackbarContext";
//components
import ScrollToTopBtn from "./components/ScrollToTop";
import Header from "./components/header";
import Footer from "./components/footer";
//pages
import Home from "./pages/home";
import BoothList from "./pages/BoothList";
import ProductList from "./pages/ProductList";
import SpecialProductList from "./pages/SpecialProductList";
import ItemDetail from "./pages/ItemDetail";
import EmbedPage from "./pages/EmbedPage";

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const Layout = ({ children }) => {
  return (
    <Location>
      {({ location }) => {
        let isEmbed = false;
        if (location.pathname.includes("embed")) {
          isEmbed = true;
        }
        return (
          <div id="routerhang">
            {!isEmbed && <Header />}
            {children}
            {!isEmbed && <Footer />}
          </div>
        );
      }}
    </Location>
  );
};
const App = () => {
  return (
    <div className="wrapper">
      <GlobalStyles />
      <LanguageContextProvider>
        <SnackbarContextProvider>
          <Layout>
            <ScrollTop>
              <Router>
                <Home path="/" />
                <BoothList path="/booth-list" />
                <ProductList path="/product-list" />
                <SpecialProductList path="/special-product-list" />
                {[
                  "/booth/:boothID",
                  "/booth/:boothID/private",
                  "/product/:productID",
                  "/product/:productID/private",
                ].map((path,index) => {
                  return <ItemDetail key={index} path={path} />;
                })}
                {[
                  "/embed/booth/:ID",
                  "/embed/booth/:ID/private",
                  "/embed/product/:ID",
                  "/embed/product/:ID/private",
                ].map((path,index) => {
                  return <EmbedPage key={index} path={path} />;
                })}
              </Router>
            </ScrollTop>
          </Layout>
        </SnackbarContextProvider>
      </LanguageContextProvider>
      <ScrollToTopBtn />
    </div>
  );
};
export default App;
