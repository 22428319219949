import { getBooth, getProduct } from "../../services/public";
import { apiMiddleware } from "../../utils/middleware";

export const handleGetBoothAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, getBooth, ID);
  if (success) {
    const { ID, company, content, createdAt, createdBy, files, objectData, name, status, type, totalView, liked, totalLike } = data;

    setMainState({
      ID,
      company,
      content,
      createdAt,
      createdBy,
      files,
      objectData,
      name,
      status,
      type,
      totalView,
      totalLike,
      liked,
    });
  }
};

export const handleGetProductAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, getProduct, ID);
  if (success) {
    const { ID, company, content, createdAt, createdBy, files, name, status, type, totalView, totalLike, liked } = data;

    setMainState({
      ID,
      company,
      content,
      createdAt,
      createdBy,
      files,
      name,
      status,
      type,
      totalView,
      totalLike,
      liked,
    });
  }
};
