import fetchData from "../../utils/fetchData";

export function getHomeItemList() {
  return fetchData({
    url: "/home",
    method: "GET",
  });
}

export function getCategory() {
  return fetchData({
    url: "/category",
    method: "GET",
  });
}

export function getBoothList(params) {
  return fetchData({
    url: "/booth",
    method: "GET",
    params: params,
  });
}

export function getBoothRelatedList(boothID) {
  return fetchData({
    url: `/booth/${boothID}/related`,
    method: "GET",
  });
}

export function updateBoothLike(boothID) {
  return fetchData({
    url: `/booth/${boothID}/like`,
    method: "PUT",
  });
}

export function getProductList(params) {
  return fetchData({
    url: "/product",
    method: "GET",
    params: params,
  });
}

export function getSpecialProductList(params) {
  return fetchData({
    url: "/special-product",
    method: "GET",
    params: params,
  });
}

export function getProductRelatedList(productID) {
  return fetchData({
    url: `/product/${productID}/related`,
    method: "GET",
  });
}

export function updateProductLike(productID) {
  return fetchData({
    url: `/product/${productID}/like`,
    method: "PUT",
  });
}

export function contactUs(body) {
  return fetchData({
    url: `/contact-form`,
    method: "POST",
    data:body,
  });
}
