import React from "react";
import Select from "react-select";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { createGlobalStyle } from "styled-components";
import { useLocation } from "@reach/router";
import CustomHelmetTitle from "../components/CustomHelmetTitle";
//hooks
import useQuery from "../hooks/useQuery";
//component
import BoothColumn from "../components/BoothColumn";
//controller
import { handleGetBoothListAPI } from "../controller/general";
import { useLanguageProps } from "../contexts/language";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
  footer {
    background: #212428;
  }
`;

const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#212428",
    color: "#fff",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#16181b",
    },
  }),
  menu: (base) => ({
    ...base,
    background: "#212428 !important",
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  control: (base, state) => ({
    ...base,
    padding: 2,
  }),
};

const options = [
  { value: "All categories", label: "All categories" },
  { value: "Art", label: "Art" },
  { value: "Music", label: "Music" },
  { value: "Domain Names", label: "Domain Names" },
];

const BoothList = () => {
  const { setSnackbar } = useSnackbarProps();
  const location = useLocation();
  const [query, setQuery] = useQuery();
  const { languagePackage } = useLanguageProps();
  const [mainState, _setMainState] = React.useState({
    list: [],
    page: 1,
    limit: 10,
    order: "desc",
    orderBy: "createdAt",
    companyID: 0,
    status: 0,
    category: query.id || 0,
    // page: query.page ? parseInt(query.page) : 1,
    // limit: query.limit ? parseInt(query.limit) : 10,
    // order: query.order || "desc",
    // orderBy: query.orderBy || "createdAt",
    // companyID: query.companyID ? parseInt(query.companyID) : 0,
    // status: query.status ? parseInt(query.status) : 0,
    search: "",
    total: 10,
    refreshData: false,
  });

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const _handleGetBoothList = async () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      companyID: mainState.companyID,
      status: mainState.status,
      category: mainState.category,
    };
    const param = {
      ...queryObj,
      changeContext: setSnackbar,
      setMainState: setMainState,
    };
    // setQuery(queryObj);
    handleGetBoothListAPI(param);
  };

  const handleSearch = () => {
    _handleGetBoothList();
  };

  React.useEffect(() => {
    _handleGetBoothList();
  }, [mainState.category]);
  // }, [mainState.companyID, mainState.page, mainState.order, mainState.orderBy]);

  React.useEffect(() => {
    if(query.id){
      setMainState({category: query.id})
    }
  }, [query]);
  return (
    <div>
      <CustomHelmetTitle title={"Capritech - Booth List"} />
      <div
        className="custom-background"
        style={{ backgroundImage: `url(${"./img/background/final-background-1.jpg"})`, minHeight: "80vh" }}
      >
        <GlobalStyles />
        <div className="custom-top-list-title-container">
          <div
            className="custom-title text-center"
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            {languagePackage.BOOTH}
            <div style={{ height: 2, width: 80, backgroundColor: "#8364e2" }}></div>
          </div>
        </div>
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="items_filter">
                <div className="row form-dark" id="form_quick_search" name="form_quick_search">
                  <div className="col">
                    <input
                      className="form-control"
                      placeholder={languagePackage.SEARCH_ITEM}
                      type="text"
                      style={{ marginBottom: 0 }}
                      onChange={(event) => {
                        setMainState({ search: event.target.value });
                      }}
                    />
                    <button id="btn-submit" onClick={() => handleSearch()}>
                      <i className="fa fa-search bg-color-secondary"></i>
                    </button>
                    <div className="clearfix"></div>
                  </div>
                </div>
                {/* <div className="dropdownSelect one">
                  <Select
                    className="select1"
                    styles={customStyles}
                    menuContainerStyle={{ zIndex: 999 }}
                    defaultValue={options[0]}
                    options={options}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <BoothColumn boothList={mainState.list} />
        </div>
      </div>
    </div>
  );
};
export default BoothList;
