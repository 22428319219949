import React, { createContext, useContext, useState, useEffect } from "react";
import CustomSnackbar from "../../components/CustomSnackbar";

export const SnackbarContext = createContext({
  snackbar: false,
  snackbarType: 0,
  snackbarContent: "",
  setSnackbar: Function,
});

const getLocalSnackbarItems = () => {
  try {
    const list = localStorage.getItem("EASYSHOW_SNACKBAR");
    if (list === null) {
      return { snackbar: false, snackbarType: 0, snackbarContent: "" };
    } else {
      return JSON.parse(list);
    }
  } catch (err) {
    return { snackbar: false, snackbarType: 0, snackbarContent: "" };
  }
};

// export const snackbar = useContext(SnackbarContext);
export const useSnackbarProps = () => useContext(SnackbarContext);

export const SnackbarContextProvider = (props) => {
  const [snackbar, _setSnackbar] = useState(getLocalSnackbarItems());
  const setSnackbar = (newState) => {
    _setSnackbar((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    localStorage.setItem("EASYSHOW_SNACKBAR", JSON.stringify(snackbar));
  }, [snackbar]);

  return (
    <SnackbarContext.Provider
      value={{
        ...snackbar,
        setSnackbar: setSnackbar,
      }}
    >
      {props.children}
      <CustomSnackbar
        open={snackbar.snackbar}
        type={snackbar.snackbarType}
        content={snackbar.snackbarContent}
        handleIsOpen={setSnackbar}
      />
    </SnackbarContext.Provider>
  );
};
