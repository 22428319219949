const en = {
  //Common
  CHINESE: "繁體中文",
  ENGLISH: "English",
  BACK: "Back",
  MORE_DETAILS: "More Details",
  LOAD_MORE: "Load More",
  SUBMIT: "Submit",
  ENTER: "Enter your",
  CANCEL: "Cancel",
  COPY:"Copy",
  SEARCH_ITEM:"search item here...",
  CREATED_DATE: "Create Date",
  //Header
  HOMEPAGE: "Home",
  BOOTH: "Booth",
  PRODUCT: "Product",
  SPECIAL: "Special Product",
  CONTACT_US: "Contact Us",
  //Footer
  COMPANY_NAME: "Hong Shun Event Services Co, Ltd",
  ADDRESS: " 12F.-5, No. 79, Sec. 1, Xintai 5th Rd., Xizhi Dist.,New Taipei City,22101 Taipei City, Taiwan",
  PHONE_NUMBER: "+886 12312312312",
  RESOURCES: "Resources",
  HELP: "Help Center",
  TERM: "Terms of Service",
  POLICY: "Policy",
  NEWS_LETTER: "Newsletter",
  SUB_NEWS_TITLE: "Signup for our newsletter to get the latest news in your inbox.",
  ENTER_EMAIL: "Enter your email",
  SUB_NEWS_TITLE_2: "Your email is safe with us. We don't spam.",
  //Home
  BANNER_TITTLE: "Designed for booth display and product display",
  BANNER_SUBTITLE: "#Free to look wherever you want",
  BANNER_BUTTON: "Explore",
  PRODUCT_3D: "3D Product",
  EXPOSE: "Expose",
  NEW_MODEL: "New Models",
  SERVICE: "Share and embed 3D models <br /> anywhere online",
  SERVICE_1: "Market-leading 3D player for the web.",
  SERVICE_2: "Interactive and configurable, VR and AR ready.",
  SERVICE_3: "Works with all operating systems, browsers and devices.",
  SERVICE_4: "Embeddable everywhere, for eCommerce, advertising and social media",
  HOW_WORK: 'How <span className="text-color">Capritech </span>',
  WORK_STEP_1: "Create your 3D Product",
  WORK_SUB_1:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
  WORK_STEP_2: "Upload to Platform",
  WORK_SUB_2:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
  WORK_STEP_3: "Share your 3D Product",
  WORK_SUB_3:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
  //Contact Us Pop Up
  OPTION: "Option",
  OPTION_1: "For further information, please contact me",
  OPTION_2: "Offer a suggestion",
  NAME: "Name",
  EMAIL: "Email",
  MESSAGE_OPTION: "Message options",
  MESSAGE: "Message",
  ENTER_NAME: "Enter your name",
  ENTER_MESSAGE: "Enter your message",
  //Embed Pop Up
  EMBED_POP_UP_TITLE: "Embed 3D modules",
  LINK: "Link",
  //Item Details Page
  ITEM_DETAILS: "Detailed information",
  CREATOR: " Creator",
  CREATOR_COMPANY: "Creator's Company",
  SHARE: "Share",
  EMBED: "Embed",
  //Model Loader
  FILE_NO_FOUND: "File not found",
  FILE_LOAD_FAIL: "Fail to load 3D model",
  //Message
  COPY_SUCCESS: "Copy Successfully!",
};

export default en;
