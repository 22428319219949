import React from "react";
import clsx from "clsx";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLanguageProps } from "../contexts/language";

const CustomCopyButton = ({
  copyText,
  buttonText = "",
  customStyle,
  isShareButton,
  isCopyButton,
  // handleClick = ()=>{},
}) => {
  const { setSnackbar } = useSnackbarProps();
  const { languagePackage } = useLanguageProps();
  return (
    <CopyToClipboard
      text={copyText}
      onCopy={() => {
        setSnackbar &&
          setSnackbar({
            snackbar: true,
            snackbarType: 0,
            snackbarContent: languagePackage.COPY_SUCCESS,
          });
      }}
    >
      <span
        className={clsx(isCopyButton && "custom-copy-button", !isCopyButton && "custom-button")}
        style={customStyle}
      >
        {isShareButton && <i className="fa fa-share" aria-hidden="true" />}
        {buttonText}
      </span>
    </CopyToClipboard>
  );
};
export default CustomCopyButton;
