const zh_tw = {
  //Common
  CHINESE: "繁體中文",
  ENGLISH: "English",
  BACK: "返回",
  MORE_DETAILS: "更多資訊",
  LOAD_MORE: "加載更多",
  SUBMIT: "提交",
  ENTER: "輸入",
  CANCEL: "取消",
  COPY: "複製",
  SEARCH_ITEM:"搜索……",
  CREATED_DATE: "創建日期",
  //Header
  HOMEPAGE: "首頁",
  BOOTH: "空間設計",
  PRODUCT: "產品設計",
  SPECIAL: "特殊物品",
  CONTACT_US: "聯繫我們",
  //Footer
  COMPANY_NAME: "依斯博萊斯活動方案有限公司(泓順集團) ",
  ADDRESS: " 12F.-5, No. 79, Sec. 1, Xintai 5th Rd., Xizhi Dist.,New Taipei City,22101 Taipei City, Taiwan",
  PHONE_NUMBER: "+886 12312312312",
  RESOURCES: "資源",
  HELP: "幫助中心",
  TERM: "服務條款",
  POLICY: "隱私條款",
  NEWS_LETTER: "通讯",
  SUB_NEWS_TITLE: "注册我们的时事通讯，以获得最新消息。",
  ENTER_EMAIL: "輸入您的電子郵件",
  SUB_NEWS_TITLE_2: "您的邮件在我们这里是安全的。",
  //Home
  BANNER_TITTLE: "專為空間展示及<br />產品展示打造",
  BANNER_SUBTITLE: "#自由自在，想看那裡就看那裡",
  BANNER_BUTTON: "探索",
  PRODUCT_3D: "3D產品",
  EXPOSE: "觀看數",
  NEW_MODEL: "最新模型",
  SERVICE: "分享和嵌入3D模型<br />在線上任何地方",
  SERVICE_1: "市場領先的3D網路播放器。",
  SERVICE_2: "準備好您的VR和AR，為您的交互式和可配置做好準備",
  SERVICE_3: "適用於所有操作系統，瀏覽器和設備.",
  SERVICE_4: "嵌入式功能無處不在，適用於電子商務，廣告和社交媒體",
  HOW_WORK: '<span className="text-color">Capritech </span>如何運作',
  WORK_STEP_1: "創建您的3D產品",
  WORK_SUB_1:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
  WORK_STEP_2: "上傳至平台",
  WORK_SUB_2:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
  WORK_STEP_3: "分享您的3D的產品",
  WORK_SUB_3:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
  //Contact Us Pop Up
  OPTION: "選項",
  OPTION_1: "有事洽詢，請與我聯繫",
  OPTION_2: "提供建議",
  NAME: "姓名",
  EMAIL: "電子郵件",
  MESSAGE_OPTION: "留言選項",
  MESSAGE: "留言",
  ENTER_NAME: "輸入您的姓名",
  ENTER_MESSAGE: "輸入留言",
  //Embed Pop Up
  EMBED_POP_UP_TITLE: "嵌入3D模組",
  LINK: "鏈接",
  //Item Details Page
  ITEM_DETAILS: "詳細資料",
  CREATOR: "創作者",
  CREATOR_COMPANY: "創作者公司",
  SHARE: "分享",
  EMBED: "嵌入",
  //Model Loader
  FILE_NO_FOUND: "未找到檔案",
  FILE_LOAD_FAIL: "加載3D模型失敗",
  //Message
  COPY_SUCCESS: "複製成功！",
};

export default zh_tw;
