import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
//Components
import CustomHelmetTitle from "../components/CustomHelmetTitle";
import ModelLoader from "../components/ModelLoader";
//hooks
import useWindowSize from "../hooks/useWindowSize";
//controller
import { handleGetBoothAPI, handleGetProductAPI } from "../controller/public";
import { handleGetPrivateBoothAPI, handleGetPrivateProductAPI } from "../controller/private";
//assets
import background from "../assets/img/background/final-background-1.jpg";

const EmbedPage = () => {
  const { ID } = useParams();
  const { setSnackbar } = useSnackbarProps();
  const { width,height } = useWindowSize();
  const isBooth = window.location.href.includes("booth");
  const isPrivate = window.location.href.includes("private");
  const [mainState, _setMainState] = useState({
    ID: ID || 0,
    related: [],
    company: { ID: 0, name: "", icon: "" },
    objectData: {},
    files: {
      model: { ID: 0, name: "", path: "" },
      thumbnail: { ID: 0, name: "", path: "" },
    },
    name: "",
    content: "",
    totalView: 0,
    liked: false,
    like: "18",
    createdBy: "",
    status: 0,
    type: 0,
  });

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleDetailAPI = (ID) => {
    const param = {
      ID: ID,
      changeContext: setSnackbar,
      setMainState: setMainState,
    };
    if (isBooth) {
      handleGetBoothAPI(param);
    } else {
      handleGetProductAPI(param);
    }
  };

  const handlePrivateDetailAPI = (ID) => {
    const param = {
      ID: ID,
      changeContext: setSnackbar,
      setMainState: setMainState,
    };
    if (isBooth) {
      handleGetPrivateBoothAPI(param);
    } else {
      handleGetPrivateProductAPI(param);
    }
  };

  useEffect(() => {
    if (isPrivate) {
      handlePrivateDetailAPI(ID);
    } else {
      handleDetailAPI(ID);
    }
  }, []);


  return (
    <div>
      <CustomHelmetTitle title={`Capritech - ${mainState.name}`} />

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {mainState.files.model.path && (
          <ModelLoader
            link={mainState.files.model.path}
            width={width}
            height={height}
            defaultData={mainState.objectData}
          />
        )}
      </div>
    </div>
  );
};
export default EmbedPage;
