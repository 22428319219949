import React from "react";
import { Interweave } from "interweave";
import { createGlobalStyle } from "styled-components";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useLanguageProps } from "../contexts/language/index";
//components
import Particle from "../components/Particle";
import SliderMainParticle from "../components/SliderMainParticle";
import FeatureBox from "../components/FeatureBox";
import CarouselNew from "../components/CarouselNew";
// import CarouselCollection from '../components/CarouselCollection';
// import AuthorList from '../components/authorList';
//controller
import { handleGetHomeItemsListAPI } from "../controller/general";

const GlobalStyles = createGlobalStyle`
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .navbar .mainside a{
    background: #8364e2;
    &:hover{
      box-shadow: 2px 2px 20px 0px #8364e2;
    }
  }
  .btn-main{
    background: #8364e2;
    &:hover{
      box-shadow: 2px 2px 20px 0px #8364e2;
    }
  }
  p.lead{
    color: #a2a2a2;
    font-size:20px;
  }
  .navbar .navbar-item .lines{
    border-bottom: 2px solid #8364e2;
  }
  .jumbotron.no-bg{
    height: 100vh;
    overflow: hidden;
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  #tsparticles{
    top: 0;
  }
  .text-uppercase.color{
    color: #8364e2;
  }
  .de_count h3 {
    font-size: 36px;
    margin-bottom: 0px;
  }
  .de_count h5{
    font-size: 14px;
    font-weight: 500;
  }
  h2 {
    font-size: 30px;
  }
  .box-url{
    text-align: center;
    h4{
      font-size: 16px;
    }
  }
  .de_countdown{
    border: solid 2px #8364e2;
  }
  .author_list_pp, .author_list_pp i, 
  .nft_coll_pp i, .feature-box.style-3 i, 
  footer.footer-light #form_subscribe #btn-subscribe i, 
  #scroll-to-top div{
    background: #8364e2;
  }
  footer.footer-light .subfooter .social-icons span i{
    background: #403f83;
  }

  .feature-box.style-3 i.wm{
    color: rgba(131,100,226, .1);
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const Home = () => {
  const { languagePackage } = useLanguageProps();
  const { setSnackbar } = useSnackbarProps();
  const [mainState, _setMainState] = React.useState({
    itemList: [],
    total3D: 0,
    totalLikeShare: 0,
    productCategory: [],
    boothCategry: []
  });

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    handleGetHomeItemsListAPI(setSnackbar, setMainState);
  }, []);

  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron no-bg" style={{ backgroundImage: `url(${"./img/background/8.jpg"})` }}>
        <Particle />
        <SliderMainParticle total3D={mainState.total3D} totalLikeShare={mainState.totalLikeShare} />
      </section>
      <div className="container no-top no-bottom">
        <div className="row">
          <div className="spacer-double"></div>
          <div className="col-lg-12 mb-2">
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
              <h2>{languagePackage.NEW_MODEL}</h2>
              <div style={{ height: 2, width: 100, backgroundColor: "#8364e2" }} />
            </div>
          </div>
        </div>
        <div className="row">
          <CarouselNew itemList={mainState.itemList} />
        </div>
      </div>
      <section
        className="container no-bottom"
        style={{ alignItems: "center", marginBottom: 80, marginTop: 20 }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 30 }}>
          <h2 style={{ textAlign: "center" }}>
            <Interweave content={languagePackage.SERVICE} />
          </h2>
          <div style={{ height: 2, width: 100, backgroundColor: "#8364e2" }} />
        </div>

        <div className="row" style={{ alignItems: "center", justifyContent: "center" }}>
          <div className="col-lg-6 col-md-6 col-sm-4 col-6 mb30" style={{ maxWidth: 300 }}>
            <span className="box-url" style={{ minHeight: 250 }}>
              <img
                src="./img/wallet/001-crown.png"
                alt=""
                className="mb20"
                style={{ width: 125, marginBottom: 8, marginTop: -10 }}
              />
              <h4>{languagePackage.SERVICE_1}</h4>
            </span>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-4 col-6 mb30" style={{ maxWidth: 300 }}>
            <span className="box-url" style={{ minHeight: 250 }}>
              <img src="./img/wallet/007-intersection.png" alt="" className="mb20" />
              <h4>{languagePackage.SERVICE_2}</h4>
            </span>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-4 col-6 mb30" style={{ maxWidth: 300 }}>
            <span className="box-url" style={{ minHeight: 250 }}>
              <img src="./img/wallet/006-application-1.png" alt="" className="mb20" />
              <h4>{languagePackage.SERVICE_3}</h4>
            </span>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-4 col-6 mb30" style={{ maxWidth: 300 }}>
            <span className="box-url" style={{ minHeight: 250 }}>
              <img src="./img/wallet/008-website-1.png" alt="" className="mb20" />
              <h4>{languagePackage.SERVICE_4}</h4>
            </span>
          </div>
        </div>
      </section>

      <div className="container no-top">
        <div className="row" style={{ marginBottom: 100, marginTop: 50 }}>
          <div className="spacer-double"></div>
          <div className="col-lg-12 mb-3">
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
              <h2>
                <Interweave content={languagePackage.HOW_WORK} />
              </h2>
              <div style={{ height: 2, width: 100, backgroundColor: "#8364e2" }} />
            </div>
          </div>
          <FeatureBox />
        </div>
      </div>
    </div>
  );
};
export default Home;
