import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

import { useLanguageProps } from "../contexts/language/index";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const FeatureBox = () => {
  const { languagePackage } = useLanguageProps();

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3">
          <Reveal className="onStep" keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className="bg-color-2 i-boxed icon_tools"></i>
          </Reveal>
          <div className="text">
            <Reveal className="onStep" keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">{languagePackage.WORK_STEP_1}</h4>
            </Reveal>
            <Reveal className="onStep" keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">{languagePackage.WORK_SUB_1}</p>
            </Reveal>
          </div>
          <i className="wm icon_tools"></i>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3">
          <Reveal className="onStep" keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className=" bg-color-2 i-boxed icon_cloud-upload_alt"></i>
          </Reveal>
          <div className="text">
            <Reveal className="onStep" keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">{languagePackage.WORK_STEP_2}</h4>
            </Reveal>
            <Reveal className="onStep" keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">{languagePackage.WORK_SUB_2}</p>
            </Reveal>
          </div>
          <i className="wm icon_cloud-upload_alt"></i>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3">
          <Reveal className="onStep" keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className=" bg-color-2 i-boxed icon_link_alt"></i>
          </Reveal>
          <div className="text">
            <Reveal className="onStep" keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">{languagePackage.WORK_STEP_3}</h4>
            </Reveal>
            <Reveal className="onStep" keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">{languagePackage.WORK_SUB_3}</p>
            </Reveal>
          </div>
          <i className="wm icon_link_alt"></i>
        </div>
      </div>
    </div>
  );
};
export default FeatureBox;
