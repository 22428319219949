import fetchData from "../../utils/fetchData";

export function getPrivateBooth(boothID) {
  return fetchData({
    url: `/booth/private/${boothID}`,
    method: "GET",
  });
}

export function getPrivateProduct(productID) {
    return fetchData({
      url: `/product/private/${productID}`,
      method: "GET",
    });
  }
