import React, { useEffect, useRef, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { useNavigate } from "@reach/router";
import { Link } from "@reach/router";
import useOnclickOutside from "react-cool-onclickoutside";
import { createGlobalStyle } from "styled-components";
import SelectLanguage from "./SelectLanguage";

import logo from "../assets/img/logo.png";
import ContactPopUp from "./popup/ContactPopUp";
import { handleContactUsAPI, handleGetCategoryListAPI } from "../controller/general";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useLanguageProps } from "../contexts/language";
import CategoryDropDown from "./CategoryDropDown";

const GlobalStyles = createGlobalStyle`
  .navbar a .active::before{
    cursor: pointer !important;
  },
`;
setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        // className: isCurrent ? "active" : "non-active",
        className: isCurrent ? "active" : "active",
      };
    }}
  />
);

const Header = () => {
  const contactRef = useRef();
  const { languagePackage } = useLanguageProps();
  const { setSnackbar } = useSnackbarProps();
  const navigate = useNavigate();
  // const [openMenu, setOpenMenu] = React.useState(false);
  const [showmenu, btn_icon] = useState(false);
  const [mainState, setMainState] = React.useState({
    productCategory: [],
    boothCategory: []
  });

  const handleBtnClick = () => {
    navigate("/");
    btn_icon(!showmenu);
  };

  const closeMenu = (link) => {
    navigate(link);
    btn_icon(!showmenu);
  };

  // const ref = useOnclickOutside(() => {
  //   closeMenu();
  // });

  const handleOpenContactPopUp = () => {
    contactRef.current.handleOpen();
  };

  const handleSubmit = (details) => {
    const param = {
      ...details,
      changeContext: setSnackbar,
    };
    handleContactUsAPI(param).then((isSuccess) => {
      if (isSuccess) {
        contactRef.current.handleClose();
      }
    });
  };

  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
      if (window.pageYOffset > sticky) {
        // closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [window.location.pathname]);

  useEffect(() => {
    handleGetCategoryListAPI(setSnackbar, setMainState, languagePackage);
  }, [])

  return (
    <header id="myHeader" className="navbar white">
      <GlobalStyles />
      <div className="container">
        <div className="row w-100-nav">
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                {/* <img src="./img/logo-3.png" className="img-fluid d-block" alt="#" /> */}
                {/* <img src="./img/logo-3.png" className="img-fluid d-3" alt="#" /> */}
                {/* <img src="./img/logo12.png" className="img-fluid d-block" alt="#" />
                <img src="./img/logo12.png" className="img-fluid d-3" alt="#" /> */}
                <img src={logo} className="img-fluid" alt="#" />
              </NavLink>
            </div>
          </div>
          {/* {isHomePage && (
            <div className="search">
              <input
                id="quick_search"
                className="xs-hide"
                name="quick_search"
                placeholder="search item here..."
                type="text"
              />
            </div>
          )} */}

          <BreakpointProvider>
            <Breakpoint l down style={{ background: "#212428", marginTop: 15 }}>
              {showmenu && (
                <div className="menu">
                  <div className="navbar-item" onClick={handleBtnClick}>
                    {/* <div ref={ref}> */}
                    <div>
                      <div className="dropdown-custom dropdown-toggle dropdown-custom-nonArrow btn">
                        <NavLink to="/">{languagePackage.HOMEPAGE}</NavLink>
                      </div>
                    </div>
                  </div>
                  <div
                    className="navbar-item"
                    onClick={() => {
                      closeMenu("/booth-list");
                    }}
                  >
                    <div>
                      <div className="dropdown-custom dropdown-toggle dropdown-custom-nonArrow btn">
                        <NavLink to="/booth-list">{languagePackage.BOOTH}</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="navbar-item" onClick={() => closeMenu("/product-list")}>
                    <div>
                      <div className="dropdown-custom dropdown-toggle dropdown-custom-nonArrow btn">
                        <NavLink to="/product-list">{languagePackage.PRODUCT}</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="navbar-item">
                    <SelectLanguage />
                  </div>
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                <div className="navbar-item" onClick={handleBtnClick}>
                  <div>
                    {/* <div ref={ref}> */}
                    <div
                      className="dropdown-custom dropdown-toggle dropdown-custom-nonArrow btn"
                      onMouseEnter={() => handleBtnClick}
                      // onMouseLeave={closeMenu}
                    >
                      <NavLink to="/">{languagePackage.HOMEPAGE}</NavLink>
                      <span className="lines"></span>
                    </div>
                  </div>
                </div>
                <div className="navbar-item" style={{marginRight: '15px'}}>
                  {/* <div  ref={ref}> */}
                    <div className="">
                      {/* <NavLink to="/booth-list">{languagePackage.BOOTH}</NavLink> */}
                      <CategoryDropDown 
                        options={mainState.boothCategory} 
                        path={'/booth-list'} 
                        pathData={window.location}
                      />
                      {/* <span className="lines"></span> */}
                  </div>
                </div>
                <div className="navbar-item" style={{marginRight: '15px'}}>
                  {/* <div ref={ref}> */}
                  <div>
                      <CategoryDropDown 
                        options={mainState.productCategory} 
                        path={'/product-list'}
                        pathData={window.location}
                      />
                  </div>
                </div>
                <div className="navbar-item" onClick={() => navigate("/special-product-list")}>
                  <div className="dropdown-custom dropdown-toggle dropdown-custom-nonArrow btn">
                    <NavLink to="/special-product-list">{languagePackage.SPECIAL}</NavLink>
                    <span className="lines"></span>
                  </div>
                </div>
                <div className="navbar-item">
                  <div className="">
                    <SelectLanguage />
                  </div>
                </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className="mainside">
            <span className="btn-main" onClick={() => handleOpenContactPopUp()}>
              {languagePackage.CONTACT_US}
            </span>
            {/* <Link to="/" className="btn-main">聯繫我們</Link> */}
          </div>
        </div>
        <ContactPopUp ref={contactRef} handleSubmit={handleSubmit} />
        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
};
export default Header;
