import React, { useState, useImperativeHandle } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { useLanguageProps } from "../../contexts/language";

const customStyles = {
  overlay: {
    zIndex: 999,
    backgroundColor: "rgb(12 12 12 / 75%",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px 30px",
    minWidth: "300px",
    backgroundColor: "#212428",
  },
};
const customSelectStyles = {
  option: (style) => ({
    ...style,
    borderBottom: "1px dotted pink",
    color: "red !important",
  }),
  menu: () => ({ cursor: "pointer", backgroundColor: "red !important" }),
  control: (style) => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    ...style,
    backgroundColor: "#212428 !important",
    color: "#a2a2a2",
  }),
};

const ContactPopUp = React.forwardRef((props, ref) => {
  const { handleSubmit } = props;
  const { selectedLanguage, languagePackage } = useLanguageProps();
  const [mainState, _setMainState] = useState({
    name: "",
    email: "",
    subject: { label: `-${languagePackage.OPTION}-`, value: 0 },
    content: "",
    isOpen: false,
  });

  const options = [
    { label: languagePackage.OPTION_1, value: 1 },
    { label: languagePackage.OPTION_2, value: 2 },
  ];

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleClearData = () => {
    setMainState({
      name: "",
      email: "",
      subject: { label: `-${languagePackage.OPTION}-`, value: 0 },
      content: "",
      isOpen: false,
    });
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    handleClearData();
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  const handleTextInput = (event, key) => {
    setMainState({ [key]: event.target.value });
  };

  const handleDropdown = (option) => {
    setMainState({ subject: option });
  };

  const _handleSubmit = () => {
    const returnParams = {
      name: mainState.name,
      email: mainState.email,
      subject: mainState.subject.value,
      content: mainState.content,
    };
    handleSubmit(returnParams);
  };

  return (
    <Modal isOpen={mainState.isOpen} onRequestClose={handleClose} style={customStyles} ariaHideApp={false}>
      <div className="row popup-title">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 style={{ color: "#a2a2a2" }}>{languagePackage.CONTACT_US}</h5>
          <div
            style={{ cursor: "pointer", display: "flex", justifyContent: "flex-end" }}
            onClick={handleClose}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 spacer">
          <div className="label">{languagePackage.NAME}</div>
          <input
            className="form-control-contact"
            placeholder={languagePackage.ENTER_NAME}
            type="text"
            style={{ marginBottom: 0 }}
            onChange={(event) => {
              handleTextInput(event, "name");
            }}
          />
        </div>
        <div className="col-lg-6 spacer">
          <div className="label">{languagePackage.EMAIL}</div>
          <input
            className="form-control-contact"
            placeholder={languagePackage.ENTER_EMAIL}
            type="text"
            style={{ marginBottom: 0 }}
            onChange={(event) => {
              handleTextInput(event, "email");
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 spacer">
          <div className="label">{languagePackage.MESSAGE_OPTION}</div>
          <Select
            className="select-contact"
            menuContainerStyle={{ zIndex: 999 }}
            // defaultValue={options[0]}
            options={options}
            style={customSelectStyles}
            value={mainState.subject}
            onChange={(event) => handleDropdown(event)}
          />
        </div>
        <div className="col-lg-12 spacer">
          <div className="label">{languagePackage.MESSAGE}</div>
          {/* <input
            className="form-control-contact"
            placeholder="输入留言"
            type="text"
            style={{ marginBottom: 0 }}
            onChange={(event) => {
              handleTextInput(event, "content");
            }}
          /> */}

          <div className="form-control-contact">
            <textarea
              placeholder={languagePackage.ENTER_MESSAGE}
              type="text"
              style={{ marginBottom: 0 }}
              onChange={(event) => {
                handleTextInput(event, "content");
              }}
            />
          </div>
        </div>
      </div>
      <div className="custom-button-container" style={{ marginTop: 15 }}>
        <button className="custom-button" onClick={() => _handleSubmit()}>
          {languagePackage.SUBMIT}
        </button>
        <button
          className="custom-button"
          style={{ backgroundColor: "transparent", color: "white" }}
          onClick={() => handleClose()}
        >
          {languagePackage.CANCEL}
        </button>
      </div>
    </Modal>
  );
});
export default ContactPopUp;
