import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//component
//assets
import LeftArrow from "../assets/img/arrow-left-1.png";
import RightArrow from "../assets/img/arrow-right-1.png";
import companyLogo from "../assets/img/product/logo.png";
import showImg from "../assets/img/showImg/ShowImg3.png";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        backgroundImage: `url(${LeftArrow})`,
        backgroundSize: "cover",
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundImage: `url(${RightArrow})`,
        backgroundSize: "cover",
      }}
      onClick={onClick}
    />
  );
};

const CarouselItem = (props) => {
  const { list, handleClick } = props;
  const [state, setState] = React.useState(list);
  const settings = {
    centerMode: true,
    infinite: true,
    focusOnSelect: true,
    // centerPadding:0,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 11, //4 // >1440
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    className: "carousel-container",
    // mobileFirst: true,
    // adaptiveHeight: 300,
    afterChange: (current) => {
      const obj = state[current];
      if (obj) {
        handleChangeWithID(obj.ID, obj.type);
      }
    },
    responsive: [
      {
        breakpoint: 1440, //xl 1280
        settings: {
          slidesToShow: 9, //4
        },
      },
      {
        breakpoint: 1280, //lg 1024 //1280
        settings: {
          slidesToShow: 7, //4
        },
      },
      {
        breakpoint: 1024, //md 768 //1024
        settings: {
          slidesToShow: 5, //3
        },
      },
      {
        breakpoint: 768, //sm 425
        settings: {
          slidesToShow: 2, //2
        },
      },
      {
        breakpoint: 375, //425
        settings: {
          slidesToShow: 1, //1
        },
      },
    ],
  };

  const handleChangeWithID = (ID, type) => {
    handleClick(ID, type);
  };

  React.useEffect(() => {
    if (state.length === 0) {
      setState(list);
    }
  }, [JSON.stringify(list)]);


  return (
    <div className="custom-item-slick" style={{ padding: "70px 0px" }}>
      <Slider {...settings}>
        {state.map((item, index) => {
          return (
            <div
              key={index}
              className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-item-list-container"
              onClick={() => handleChangeWithID(item.ID, item.type)}
            >
              <div className="custom-top-background" style={{ width: "111px", height: "20px" }}>
                <div className="author_list_pp" style={{ marginLeft: 10, width: 14, height: 13 }}>
                  <img className="lazy" src={item.company ? item.company.icon : companyLogo} alt="" />
                </div>
                <div className="custom-date" style={{ marginRight: 10, fontSize: 11 }}>
                  {item.createdAt}
                </div>
              </div>
              <div className="custom-bottom-background" style={{ width: 101, height: 67 }}>
                <div className="nft__item_info" style={{ zIndex: 3 }}>
                  <h4
                    style={{
                      top: 14,
                      left: 10,
                      position: "absolute",
                      transformOrigin: "top left",
                      transform: "rotate(51deg)",
                      fontSize: 10,
                      width: "66px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {item.name}
                  </h4>
                  <div
                    className="nft__item_like"
                    style={{
                      bottom: 2,
                      left: 27,
                      position: "absolute",
                      transformOrigin: "top left",
                      transform: "rotate(52deg)",
                      fontSize: 7,
                    }}
                  >
                    <i className="fa fa-heart"></i>
                    <span style={{ fontSize: 7 }}>{item.likes}50</span>
                  </div>
                </div>
              </div>
              <div className="nft__item m-0 custom-item-background">
                <div
                  className="nft__item_wrap"
                  style={{ height: "90px", paddingTop: 25, marginTop: 0, marginBottom: 0 }}
                >
                  <span>
                    <img
                      src={item.files.thumbnail ? item.files.thumbnail.path : showImg}
                      className="lazy nft__item_preview"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default CarouselItem;
