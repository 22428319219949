import React from "react";
import { Link } from "@reach/router";
import { useLanguageProps } from "../contexts/language";

//assest
import logo from "../assets/img/logo.png";

const Footer = () => {
  const { languagePackage } = useLanguageProps();
  return (
    <footer className="footer-light">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              {/* <h5>Marketplace</h5>
                            <ul>
                                <li><Link to="">All NFTs</Link></li>
                                <li><Link to="">Art</Link></li>
                                <li><Link to="">Music</Link></li>
                                <li><Link to="">Domain Names</Link></li>
                                <li><Link to="">Virtual World</Link></li>
                                <li><Link to="">Collectibles</Link></li>
                            </ul>
                         */}
              <div className="logo px-0">
                <div className="navbar-title navbar-item">
                  <img
                    src={logo}
                    // className="img-fluid d-none"
                    alt="capritech"
                  />
                  <p style={{ marginTop: 20 }}>{languagePackage.COMPANY_NAME}</p>
                  <p>
                    {languagePackage.ADDRESS}
                    {/* 12F.-5, No. 79, Sec. 1, Xintai 5th Rd., Xizhi Dist., New Taipei City 22101 Taipei City
                    Taiwan */}
                  </p>
                  <p>{languagePackage.PHONE_NUMBER}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            {/* <div className="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><Link to="">Community</Link></li>
                                <li><Link to="">Documentation</Link></li>
                                <li><Link to="">Brand Assets</Link></li>
                                <li><Link to="">Blog</Link></li>
                                <li><Link to="">Forum</Link></li>
                                <li><Link to="">Mailing List</Link></li>
                            </ul>
                        </div> */}
          </div>

          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>{languagePackage.RESOURCES}</h5>
              <ul>
                <li>
                  <Link to="">{languagePackage.HELP}</Link>
                </li>
                <li>
                  <Link to="">{languagePackage.TERM}</Link>
                </li>
                <li>
                  <Link to="">{languagePackage.POLICY}</Link>
                </li>
                {/* <li><Link to="">Suggestions</Link></li>
                                <li><Link to="">Discord</Link></li>
                                <li><Link to="">Docs</Link></li>
                                <li><Link to="">Newsletter</Link></li> */}
              </ul>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>{languagePackage.NEWS_LETTER}</h5>
              <p>{languagePackage.SUB_NEWS_TITLE}</p>
              <form
                action="#"
                className="row form-dark"
                id="form_subscribe"
                method="post"
                name="form_subscribe"
              >
                <div className="col text-center">
                  <input
                    className="form-control"
                    id="txt_subscribe"
                    name="txt_subscribe"
                    placeholder={languagePackage.ENTER_EMAIL}
                    type="text"
                  />
                  <Link to="" id="btn-subscribe">
                    <i className="arrow_right bg-color-secondary"></i>
                  </Link>
                  <div className="clearfix"></div>
                </div>
              </form>
              <div className="spacer-10"></div>
              <small>{languagePackage.SUB_NEWS_TITLE_2}</small>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <span onClick={() => window.open("", "_self")}>
                    <span className="copy">&copy; Copyright 2021 - Atecl Technology</span>
                  </span>
                </div>
                <div className="de-flex-col">
                  <div className="social-icons">
                    <span onClick={() => window.open("", "_self")}>
                      <i className="fa fa-facebook fa-lg"></i>
                    </span>
                    {/* <span onClick={()=> window.open("", "_self")}><i className="fa fa-twitter fa-lg"></i></span>
                                        <span onClick={()=> window.open("", "_self")}><i className="fa fa-linkedin fa-lg"></i></span>
                                        <span onClick={()=> window.open("", "_self")}><i className="fa fa-pinterest fa-lg"></i></span> */}
                    <span onClick={() => window.open("", "_self")}>
                      <i className="fa fa-rss fa-lg"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
