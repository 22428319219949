import { getPrivateBooth, getPrivateProduct } from "../../services/private";
import { apiMiddleware } from "../../utils/middleware";

export const handleGetPrivateBoothAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, getPrivateBooth, ID);
  if (success) {
    const { ID, company, content, createdAt, createdBy, files, liked, name, status, totalLike, totalView, type } = data;
    setMainState({ ID, company, content, createdAt, createdBy, files, liked, name, status, totalLike, totalView, type });
  }
};

export const handleGetPrivateProductAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, getPrivateProduct, ID);
  if (success) {
    const { ID, company, content, createdAt, createdBy, files, liked, name, status, totalLike, totalView, type } = data;
    setMainState({ ID, company, content, createdAt, createdBy, files, liked, name, status, totalLike, totalView, type });
  }
};
