import React, { useState, useImperativeHandle } from "react";
import Modal from "react-modal";
import ModelLoader from "../ModelLoader";
import useWindowSize from "../../hooks/useWindowSize";
import CustomCopyButton from "../CustomCopyButton";
import { useLanguageProps } from "../../contexts/language";

const customStyles = {
  overlay: {
    zIndex: 999,
    backgroundColor: "rgb(12 12 12 / 75%",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#212428",
  },
};

const EmbedPopUp = React.forwardRef((props, ref) => {
  const { width, height } = useWindowSize();
  const { languagePackage } = useLanguageProps();
  const [mainState, setMainState] = useState({
    id: 0,
    modelPath: "",
    embedLink: "",
    objectData: {},
    isOpen: false,
  });

  const handleClearData = () => {
    setMainState({
      modelPath: "",
      embedLink: "",
    });
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      modelPath: "",
      embedLink: "",
      isOpen: false,
      objectData: {},
    });
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  let modelWidth = 425;
  let modelHeight = 300;

  if (width <= 1600) {
    modelWidth = 425;
  }
  //laptop size 1024
  if (width <= 1137) {
    modelWidth = 300;
    modelHeight = 285;
  }
  if (width <= 853) {
    modelWidth = 400;
    modelHeight = 285;
  }
  if (width <= 472) {
    modelWidth = 300;
    modelHeight = 285;
  }

  let iframeLink = `<iframe width="${width}" height="${height}" src="${mainState.embedLink}" title="Capritech 3D" ></iframe>`;
  return (
    <Modal isOpen={mainState.isOpen} onRequestClose={handleClose} style={customStyles}>
      <div className="row">
        <div className="col-lg-7">
          <div>
            {/* <div style={{ width: `${modelWidth}px`, height: `${modelHeight}px` }}> */}
            {/* <img style={{ width: "100%", height: "100%" }} src={mainState.modelPath} alt="" /> */}
            <ModelLoader
              link={mainState.modelPath}
              width={modelWidth}
              height={modelHeight}
              defaultData={mainState.objectData}
            />
          </div>
        </div>
        <div className="col-lg-5">
          <div style={{ borderBottom: "1px solid #a2a2a2", padding: "8px 0px", marginBottom: 15 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5 style={{ color: "#a2a2a2" }} className="modal-title f-w-600">
                {languagePackage.EMBED_POP_UP_TITLE}
              </h5>
              <span style={{ cursor: "pointer" }} onClick={handleClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div style={{ padding: "8px", height: "210px" }}>
            <p style={{ wordBreak: "break-word" }}>{iframeLink}</p>
            <div className="custom-button-container">
              <CustomCopyButton
                copyText={iframeLink}
                buttonText={languagePackage.COPY}
                customStyle={{
                  backgroundColor: "#8364e2",
                  color: "white",
                  fontSize: 16,
                  padding: "2px 15px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="custom-button-container">
        <CustomCopyButton
          copyText={mainState.embedLink}
          buttonText={languagePackage.COPY + languagePackage.LINK}
          customStyle={{ backgroundColor: "#8364e2", color: "white", fontSize: 16 }}
        />
        {/* <CustomCopyButton
          copyText={iframeLink}
          buttonText="複製"
          customStyle={{ backgroundColor: "#8364e2", color: "white", fontSize: 16 }}
        /> */}
      </div>
      {/* <div style={{ border: "1px solid gray", padding: "8px" }}>
        {mainState.embedLink}
        <div className="custom-button-container">
          <CustomCopyButton
            copyText={mainState.embedLink}
            buttonText="複製鏈接"
            customStyle={{ backgroundColor: "#8364e2", color: "white", fontSize: 16 }}
          />
        </div>
      </div> */}
    </Modal>
  );
});
export default EmbedPopUp;
