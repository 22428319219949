import React, { useState } from "react";
// @ts-ignore
import Select from "react-select";
import AsyncSelect from "react-select/async";
import clsx from "clsx";
import { navigate } from "@reach/router";

const CategoryDropDown = (props) => {
  const options = props.options;
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  let queryParam = props.pathData && props.pathData.search ? props.pathData.search.replace('?id=', '') : ""
  let defaultSelectedValue = props.pathData && props.pathData.search ? options.find((x) => x.value == queryParam) : options[0];
  let firstOption = options[0];

  const handleChangeSelect = (selectedOption) => {
    const value = selectedOption.value;

    navigate(props.path + '?id=' + value)

    setOpen(false);
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      display: "none",
      minWidth: 240,
      margin: 8,
      cursor: "pointer",
      marginLeft: 10,
    }),
    menu: () => ({ minWidth: 150, boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    option: (style) => ({ ...style, cursor: "pointer !important", color: "black" }),
    indicatorSeparator: (styles) => ({ display: "none !important" }),
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <div
            className="filter_container"
            onClick={() => {
              setOpen(true);
            }}
          >
            <div className="filter_inner_container">
              {/* <div className="filter_title">Language:</div> */}
              <div className="filter_selected">{firstOption ? firstOption.label : "-"}</div>
            </div>
            <div className="icon_container">
              <div className={clsx("icon ", isOpen && "icon_reverse")}>
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              </div>
              {/* <ExpandMoreIcon className={clsx("icon", isOpen && "icon_reverse")} /> */}
            </div>
          </div>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          noOptionsMessage={() => null}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={handleChangeSelect}
          options={options}
          styles={selectStyles}
          tabSelectsValue={false}
          value={props.pathData.pathname === props.path ? defaultSelectedValue : ''}
        />
      </Dropdown>
    </React.Fragment>
  );
};

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 10,
        cursor: "pointer",
        // marginLeft: 10,
      }}
      {...props}
    />
  );
};

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
      cursor: "pointer",
    }}
    {...props}
  />
);

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Svg = (p) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);

const DropdownIndicator = () => (
  <div
    style={{
      // color: colors.neutral20,
      height: 24,
      width: 32,
    }}
  >
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export default CategoryDropDown;
