import { Helmet } from "react-helmet";

const CustomHelmetTitle = ({ title = "" }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
export default CustomHelmetTitle;
