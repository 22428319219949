import React, { Component } from "react";
import { Link } from "@reach/router";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguageProps } from "../contexts/language";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

const CustomMoreDetails = (props) => {
  const { ID, type } = props.item;
  const { languagePackage } = useLanguageProps();
  //1 = booth
  //2 = product
  let url = `/booth/${ID}`;
  if (type === 2) {
    url = `/product/${ID}`;
  }
  return (
    <div className="nft__item_action">
      <span>
        <Link style={{ textDecoration: "none" }} to={url}>
          {/* <Link style={{ textDecoration: "none", color: "inherit" }} to={url}> */}
          {languagePackage.MORE_DETAILS}
          <i className="fa fa-arrow-right" style={{ marginLeft: 10 }}></i>
        </Link>
      </span>
      {/* <span>More Details</span> */}
    </div>
  );
};

const CarouselNew = (props) => {
  const { itemList } = props;
  const { languagePackage } = useLanguageProps();

  const dummyData = [
    {
      createdAt: "22/12/2022",
      link: "#",
      logo: "./img/product/logo.png",
      thumbnail: "./img/product/advanced_fan.png",
      title: "電風扇",
      likes: 50,
    },
    {
      createdAt: "22/12/2022",
      link: "#",
      logo: "./img/product/logo.png",
      thumbnail: "./img/product/dummy_product3.png",
      title: "滑鼠",
      likes: 50,
    },
    {
      createdAt: "22/12/2022",
      link: "#",
      logo: "./img/product/logo.png",
      thumbnail: "./img/product/dummy_product2.png",
      title: "咖啡機",
      likes: 80,
    },
    {
      createdAt: "22/12/2022",
      link: "#",
      logo: "./img/product/logo.png",
      thumbnail: "./img/product/dummy_product1.png",
      title: "腳踏車",
      likes: 97,
    },
    {
      createdAt: "22/12/2022",
      link: "#",
      logo: "./img/product/logo.png",
      thumbnail: "./img/product/dummy_product3.png",
      title: "滑鼠",
      likes: 50,
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: 300,
    arrows: true,
    className: "custom-carousel-slider",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="nft">
      <Slider {...settings}>
        {/* {itemList.length === 0 && <div>No Data Found</div>} */}
        {itemList.length > 0 &&
          itemList.map((item, index) => {
            const { ID, type } = item;
            //1 = booth
            //2 = product
            let url = `/booth/${ID}`;
            if (type === 2) {
              url = `/product/${ID}`;
            }

            return (
              <div className="d-item" key={index}>
                <div className="nft__item" style={{ borderRadius: 15, padding: 20 }}>
                  <div className="author_list_pp">
                    {/* <span onClick={() => window.open("/#", "_self")}> */}
                    <img className="lazy" src={item.company ? item.company.icon : ""} alt="" />
                    {/* <i className="fa fa-check"></i> */}
                    {/* </span> */}
                  </div>
                  <div className="nft__item_wrap">
                    <Outer>
                      <a href={url}>
                        <img src={item.thumbnail} className="lazy nft__item_preview" alt="" />
                      </a>
                    </Outer>
                  </div>
                  <div className="nft__item_info">
                    {/* <span onClick={() => window.open("/#", "_self")}> */}
                    <h4>{item.name}</h4>
                    {/* </span> */}
                    <div className="nft__item_price">
                      {languagePackage.CREATED_DATE}: <span>{item.createdAt}</span>
                    </div>
                    <div style={{ height: 20, width: 20 }}></div>
                    <CustomMoreDetails item={item} />
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default CarouselNew;
