import React, { useState, Component } from "react";
import { useLanguageProps } from "../contexts/language";

//assets
import companyLogo from "../assets/img/product/logo.png";

const ProductColumn = (props) => {
  const { productList, handleClick } = props;
  const { languagePackage } = useLanguageProps();
  const dummyData = [
    {
      ID: 1,
      createdAt: "22/12/2022",
      company: { icon: "./img/product/logo.png" },
      thumbnail: "./img/product/dummy_product3.png",
      name: "滑鼠",
      price: "0.08 ETH",
      likes: 50,
    },
    {
      ID: 2,
      createdAt: "22/12/2022",
      company: { icon: "./img/product/logo.png" },
      thumbnail: "./img/product/dummy_product2.png",
      name: "腳踏車",
      price: "0.06 ETH",
      likes: 80,
    },
    {
      ID: 3,
      createdAt: "22/12/2022",
      company: { icon: "./img/product/logo.png" },
      thumbnail: "./img/product/advanced_fan.png",
      name: "電風扇",
      price: "0.05 ETH",
      likes: 97,
    },
    {
      ID: 4,
      createdAt: "22/12/2022",
      company: { icon: "./img/product/logo.png" },
      thumbnail: "./img/product/dummy_product1.png",
      name: "SRW289763982376",
      //   name: "咖啡機",
      price: "0.08 ETH",
      likes: 50,
    },
  ];

  const [state, setState] = useState({
    // nfts: dummyData.slice(0, 8),
    nfts: productList.slice(0, 10),
    height: 0,
  });

  const loadMore = () => {
    let nftState = state.nfts;
    let start = nftState.length;
    let end = nftState.length + 5;
    setState({
      ...state,
      nfts: [...nftState, ...productList.slice(start, end)],
    });
  };

  React.useEffect(() => {
    setState({ ...state, nfts: productList.slice(0.8) });
  }, [JSON.stringify(productList)]);

  return (
    <div className="row custom-mobile-center">
      {state.nfts.map((nft, index) => (
        <div
          key={index}
          className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-item-container"
          onClick={() => window.open(`/product/${nft.ID}`, "_self")}
          style={{ position: "relative", cursor: "pointer" }}
        >
          <div className="custom-top-background ">
            <div className="author_list_pp" style={{ width: 16, height: 16 }}>
              <span onClick={() => window.open(`/product/${nft.ID}`, "_self")}>
                <img className="lazy" src={nft.company ? nft.company.icon : companyLogo} alt="" />
              </span>
            </div>
            <div className="custom-date">{nft.createdAt}</div>
          </div>
          <div className="custom-bottom-background">
            <div className="nft__item_info" style={{ zIndex: 3 }}>
              <span onClick={() => window.open(`/product/${nft.ID}`, "_self")}>
                <h4
                  style={{
                    top: 20,
                    left: 15,
                    position: "absolute",
                    transformOrigin: "top left",
                    transform: "rotate(47deg)",
                    fontSize: 14,
                  }}
                >
                  {nft.name}
                </h4>
              </span>
              <div
                className="nft__item_like"
                style={{
                  bottom: 13,
                  left: 55,
                  position: "absolute",
                  transformOrigin: "top left",
                  transform: "rotate(46deg)",
                }}
              >
                <i className="fa fa-heart"></i>
                <span>{nft.totalLike}</span>
              </div>
            </div>
          </div>
          <div className="nft__item m-0 custom-item-background">
            <div className="nft__item_wrap" style={{ minHeight: 120, height: `120px`, paddingTop: 10 }}>
              <span>
                <img src={nft.thumbnail} className="lazy nft__item_preview" alt="" />
              </span>
            </div>
          </div>
        </div>
      ))}
      {productList.length > 10 && (
        //  {/* {state.nfts.length !== productList.length && ( */}
        //  {state.nfts.length !== dummyData.length && (
        <div className="col-lg-12">
          <span onClick={() => loadMore()} className="btn-main lead" style={{ margin: "auto" }}>
            {languagePackage.LOAD_MORE}
          </span>
        </div>
      )}
      <div style={{ marginBottom: 50 }} />
    </div>
  );
};
export default ProductColumn;
