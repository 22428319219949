import {
  contactUs,
  getBoothList,
  getBoothRelatedList,
  getCategory,
  getHomeItemList,
  getProductList,
  getProductRelatedList,
  getSpecialProductList,
  updateBoothLike,
  updateProductLike,
} from "../../services/general";
import { apiMiddleware } from "../../utils/middleware";

export const handleGetHomeItemsListAPI = async (changeContext, setMainState) => {
  const { data, success } = await apiMiddleware(changeContext, getHomeItemList);
  if (success) {
    const { list, total3D, totalLikeShare, productCategory, boothCategory } = data;
    setMainState({ itemList: list, total3D, totalLikeShare, productCategory, boothCategory });
  }
};

export const handleGetCategoryListAPI = async (changeContext, setMainState, languagePackage) => {
  const { data, success } = await apiMiddleware(changeContext, getCategory);
  if (success) {
    const { productCategory, boothCategory } = data;
    setMainState({boothCategory: [{value: 0, label: languagePackage.BOOTH}, ...boothCategory], productCategory: [{value: 0, label: languagePackage.PRODUCT}, ...productCategory]})
  }

};

export const handleGetProductListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  companyID,
  status,
  category,
  changeContext,
  setMainState,
}) => {
  const params = {
    page,
    limit,
    order,
    orderBy,
    search,
    companyID,
    status,
    category
  };
  const { data, success } = await apiMiddleware(changeContext, getProductList, params);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
};

export const handleGetSpecialProductListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  companyID,
  status,
  changeContext,
  setMainState,
}) => {
  const params = {
    page,
    limit,
    order,
    orderBy,
    search,
    companyID,
    status,
  };
  const { data, success } = await apiMiddleware(changeContext, getSpecialProductList, params);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
};

export const handleGetBoothListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  status,
  category,
  companyID,
  changeContext,
  setMainState,
}) => {
  const params = {
    page,
    limit,
    order,
    orderBy,
    search,
    companyID,
    status,
    category
  };
  const { data, success } = await apiMiddleware(changeContext, getBoothList, params);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
};
//related List
export const handleGetBoothRelatedListAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, getBoothRelatedList, ID);
  if (success) {
    let list= data;
    let length = list.length;
    let relatedList = [];

    const fromIndex = list.indexOf(list.find((key) => key.ID === parseInt(ID)));
    const toIndex = 0;
    const element = list.splice(fromIndex, 1)[0];
    list.splice(toIndex, 0, element);

    if (length <= 12) {
      while (length <= 12) {
        relatedList = relatedList.concat(list);
        length = relatedList.length;
      }

      if (length >= 12) {
        relatedList = relatedList.slice(0, 12);
      }
    }

    setMainState({ related: relatedList });
  }
};

export const handleGetProductRelatedListAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, getProductRelatedList, ID);
  if (success) {
    let list = data;
    let length = list.length;
    let relatedList = [];

    const fromIndex = list.indexOf(list.find((key) => key.ID === parseInt(ID)));
    const toIndex = 0;
    const element = list.splice(fromIndex, 1)[0];
    list.splice(toIndex, 0, element);
    
    if (length <= 12) {
      while (length <= 12) {
        relatedList = relatedList.concat(list);
        length = relatedList.length;
      }
    }
    setMainState({ related: relatedList });
  }
};
//update like
export const handleUpdateBoothLikeAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, updateBoothLike, ID);
  if (success) {
    // setMainState({ list: data.list});
  }
};

export const handleUpdateProductLikeAPI = async ({ ID, changeContext, setMainState }) => {
  const { data, success } = await apiMiddleware(changeContext, updateProductLike, ID);
  if (success) {
    // setMainState({ list: data.list});
  }
};

export const handleContactUsAPI = async ({ name, email, subject, content, changeContext }) => {
  const body = {
    name,
    email,
    subject,
    content,
  };
  let errMessage = "";
  if (!name || name === "") {
    errMessage = "姓名不能為空白";
  }
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email))) {
    errMessage = "電子郵件格式錯誤";
  }

  if (subject === 0) {
    errMessage = "至少選一個選項";
  }

  // if(!content || content === ""){
  //   errMessage = "留言不能為空白";
  // }
  if (errMessage) {
    changeContext.snackbar = true;
    changeContext.snackbarType = 1;
    changeContext.snackbarContent = errMessage;
    changeContext(changeContext);
    return false;
  }

  const { data, success } = await apiMiddleware(changeContext, contactUs, body);
  if (success) {
    // setMainState({ list: data.list});
  }
};
