import React, { useRef, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { Interweave } from "interweave";
import { useNavigate, useParams, useLocation } from "@reach/router";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import useWindowSize from "../hooks/useWindowSize";
//component
import CarouselItem from "../components/CarouselItem";
import CustomCopyButton from "../components/CustomCopyButton";
import CustomHelmetTitle from "../components/CustomHelmetTitle";
import EmbedPopUp from "../components/popup/EmbedPopUp";
import ModelLoader from "../components/ModelLoader";
//assets
import background from "../assets/img/background/final-background-1.jpg";
import listBackground from "../assets/img/background/item-list-line-container-2.png";
import fan from "../assets/img/product/advanced_fan.png";
import companyLogo from "../assets/img/product/logo.png";
import showImg from "../assets/img/showImg/ShowImg3.png";
//controller
import { handleGetBoothAPI, handleGetProductAPI } from "../controller/public/index";
import { handleGetPrivateBoothAPI, handleGetPrivateProductAPI } from "../controller/private/index";
import {
  handleGetBoothRelatedListAPI,
  handleGetProductRelatedListAPI,
  handleUpdateBoothLikeAPI,
  handleUpdateProductLikeAPI,
} from "../controller/general";
import { useLanguageProps } from "../contexts/language";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
  footer {
    background: #212428;
  }`;

const dummyData = [
  {
    authorImg: "./img/product/logo.png",
    createdAt: "22/12/2022",
    previewImg: "./img/product/dummy_product3.png",
    link: "item-details",
    title: "滑鼠",
    likes: 50,
  },
];

const ItemDetailPage = function () {
  const location = useLocation();
  const embedRef = useRef();
  const { width } = useWindowSize();
  const { languagePackage } = useLanguageProps();
  const { setSnackbar } = useSnackbarProps();
  const { boothID, productID } = useParams();
  const navigate = useNavigate();
  const handleGoBack = (type) => {
    let url = "/booth-list";
    if (type === 2) {
      url = "/product-list";
    }
    // navigate(-1);
    navigate(url);
  };
  const isBooth = window.location.href.includes("booth");
  const isPrivate = window.location.href.includes("private");
  const [mainState, _setMainState] = useState({
    ID: boothID || productID,
    objectData: {},
    related: [],
    company: { ID: 0, name: "", icon: "" },
    files: {
      model: { ID: 0, name: "", path: "" },
      thumbnail: { ID: 0, name: "", path: "" },
    },
    name: "",
    content: "",
    createdAt: "",
    createdBy: { ID: 0, name: "", icon: "", company: { id: 0, name: "", icon: "" } },
    totalView: 0,
    liked: false,
    totalLike: 0,
    status: 0,
    type: 0,
  });

  const isFirstRelatedList = mainState.related.length === 0;
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleClick = (ID, type) => {
    let url = `/booth/${ID}`;
    if (type === 2) {
      url = `/product/${ID}`;
    }
    // navigate(url);
    navigate(url, { replace: true });
    setMainState({
      ID: ID,
      files: {
        model: { ID: 0, name: "", path: "" },
        thumbnail: { ID: 0, name: "", path: "" },
      },
    });
  };

  const handleDetailAPI = (ID) => {
    const param = {
      ID: ID,
      changeContext: setSnackbar,
      setMainState: setMainState,
    };
    if (isBooth) {
      handleGetBoothAPI(param);
      isFirstRelatedList && handleGetBoothRelatedListAPI(param);
    } else {
      handleGetProductAPI(param);
      isFirstRelatedList && handleGetProductRelatedListAPI(param);
    }
  };
  const handlePrivateDetailAPI = (ID) => {
    const param = {
      ID: ID,
      changeContext: setSnackbar,
      setMainState: setMainState,
    };
    if (isBooth) {
      handleGetPrivateBoothAPI(param);
    } else {
      handleGetPrivateProductAPI(param);
    }
  };
  const handleOpenEmbedPopUp = (link, objectData) => {
    embedRef.current.setMainState({
      modelPath: link,
      embedLink: `${location.origin}/embed/${isBooth ? "booth" : "product"}/${mainState.ID}`,
      isOpen: true,
      objectData,
    });
  };

  const handleClickLike = (ID, type) => {
    const param = {
      ID: ID,
      changeContext: setSnackbar,
      setMainState: setMainState,
    };
    if (type === 2) {
      handleUpdateProductLikeAPI(param);
    } else {
      handleUpdateBoothLikeAPI(param);
    }

    if (!mainState.liked) {
      setMainState({ liked: true, totalLike: mainState.totalLike + 1 });
    }
  };
  React.useEffect(() => {
    // let ID = mainState.ID;
    let ID = boothID || productID;
    if (isPrivate) {
      handlePrivateDetailAPI(ID);
    } else {
      handleDetailAPI(ID);
    }
  }, [mainState.ID]);

  let modelWidth = 700;
  let modelHeight = 400;

  if (width <= 1600) {
    modelWidth = 700;
  }
  //laptop size 1024
  if (width <= 1137) {
    modelWidth = 550;
    modelHeight = 300;
  }
  if (width <= 853) {
    modelWidth = 800;
    modelHeight = 400;
  }
  if (width <= 472) {
    modelWidth = 300;
    modelHeight = 285;
  }

  return (
    <div>
      <CustomHelmetTitle title={`Capritech - ${mainState.name}`} />
      <div className="custom-background" style={{ backgroundImage: `url(${background})` }}>
        <GlobalStyles />
        <div className="container custom-top-title-container">
          <div className="custom-back-button" onClick={() => handleGoBack(mainState.type)}>
            <p>{languagePackage.BACK}</p>
          </div>
        </div>
        <div className="container custom-container-margin">
          <div className="row">
            {/* <div className="row mt-md-5 pt-md-4"> */}
            <div className="col-lg-7 col-md-12 col-sm-12 text-center custom_img_container">
              {mainState.files.model.path && (
                <ModelLoader
                  link={mainState.files.model.path}
                  width={modelWidth}
                  height={modelHeight}
                  defaultData={mainState.objectData}
                  idName="embed-loader"
                />
              )}
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 custom-item-mobile-center">
              <div className="item_info">
                <h2 style={{ fontSize: 18, fontWeight: "bold" }}>{mainState.name}</h2>
                <div className="item_info_counts">
                  <div className="item_info_views">
                    <i className="fa fa-eye"></i>
                    {mainState.totalView}
                  </div>
                  {/* <div className="item_info_like">
                      <i className="fa fa-heart"></i>
                      {mainState.like}
                    </div> */}
                  <span
                    onClick={() => handleClickLike(mainState.ID, mainState.type)}
                    className="custom-copy-button"
                  >
                    <i
                      style={{ color: mainState.liked ? "#ff5050" : "", marginRight: 5 }}
                      className="fa fa-heart"
                      aria-hidden="true"
                    />
                    {mainState.totalLike}
                  </span>
                  {!isPrivate && (
                    <>
                      <span
                        onClick={() => handleOpenEmbedPopUp(mainState.files.model.path, mainState.objectData)}
                        // onClick={() =>
                        //   handleOpenEmbedPopUp(mainState.files.thumbnail.path)
                        // }
                        className="custom-copy-button"
                      >
                        <i className="fa fa-code" aria-hidden="true" />
                        {languagePackage.EMBED}
                      </span>
                      <CustomCopyButton
                        copyText={location.href}
                        buttonText={languagePackage.SHARE}
                        isShareButton={true}
                        isCopyButton={true}
                      />
                    </>
                  )}
                </div>
                <div className="custom-title-container">
                  <p>{languagePackage.ITEM_DETAILS}</p>
                </div>
                <Interweave tagName="div" className="custom-details" content={mainState.content} />
                <div className="custom-title-container">
                  <p>{languagePackage.CREATOR}</p>
                </div>
                <div className="item_author">
                  <div className="author_list_pp" style={{ marginTop: 10 }}>
                    <span>
                      <img
                        className="lazy"
                        src={mainState.createdBy ? mainState.createdBy.icon : companyLogo}
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="author_list_info" style={{ paddingTop: 16, minHeight: 45 }}>
                    <span>{mainState.createdBy ? mainState.createdBy.name : ""}</span>
                  </div>
                </div>
                <div className="custom-title-container" style={{ marginTop: 15 }}>
                  <p>{languagePackage.CREATOR_COMPANY}</p>
                </div>
                <div className="item_author">
                  <div className="author_list_pp" style={{ marginTop: 10 }}>
                    <span>
                      <img
                        className="lazy"
                        src={mainState.createdBy ? mainState.createdBy.company.icon : companyLogo}
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="author_list_info" style={{ paddingTop: 16, minHeight: 45 }}>
                    <span>{mainState.createdBy ? mainState.createdBy.company.name : ""}</span>
                  </div>
                </div>
                {/* <div className="spacer-40"></div> */}
                {/* <div className="de_tab"></div> */}
              </div>
            </div>
          </div>
        </div>
        {/* related List */}
        <div
          style={{
            backgroundImage: `url(${listBackground})`,
            backgroundSize: `100% 300px`,
            // backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {mainState.related.length !== 0 && (
            <CarouselItem list={mainState.related} handleClick={handleClick} />
          )}
        </div>
        {/* /related List */}

        <div style={{ height: 50 }} />
      </div>
      <EmbedPopUp ref={embedRef} />
    </div>
  );
};
export default ItemDetailPage;
