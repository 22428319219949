import fetchData from "../../utils/fetchData";

export function getBooth(boothID) {
  return fetchData({
    url: `/booth/${boothID}`,
    method: "GET",
  });
}

export function getProduct(productID) {
    return fetchData({
      url: `/product/${productID}`,
      method: "GET",
    });
  }
