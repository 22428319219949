import { createContext, useContext, useState, useEffect } from "react";
import zh from "./zh-tw";
import en from "./en";

const defaultValue = {
  selectedLanguage: "zh-tw",
  languagePackage: zh,
  setLanguage: Function,
};

const LanguageContext = createContext(defaultValue);

const getLocalLanguage = () => {
  try {
    const open = localStorage.getItem("EASYSHOW_LANGUAGE");
    if (open === null) {
      return defaultValue;
    } else {
      return JSON.parse(open);
    }
  } catch (err) {
    console.log("error get language local storage");
    return defaultValue;
  }
};

export { zh, en };
export const useLanguageProps = () => useContext(LanguageContext);
export const LanguageContextProvider = (props) => {
  const [language, _setLanguage] = useState(getLocalLanguage());

  const setLanguage = (newState) => {
    _setLanguage((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };
  useEffect(() => {
    localStorage.setItem("EASYSHOW_LANGUAGE", JSON.stringify(language));
  }, [language.selectedLanguage]);

  return (
    <LanguageContext.Provider
      value={{
        // ...language,
        selectedLanguage: language.selectedLanguage,
        languagePackage: language.languagePackage,
        setLanguage: setLanguage,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};
